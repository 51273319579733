<template>
    <Header :isShow="isShow" />
    <div class="warper">
        <div class="content_box" ref="header">
            <div class="content max_small_box">
                <router-link :to="`/resource/resources_education_detail/${item.id}`" class="list" v-for="(item, index) in list" :key="index" data-aos="fade-up" :data-aos-delay="(index+1)*200">
                        <div class="left">
                            <p class="title">{{item.title}}</p>
                            <p class="desc">{{item.intro}}</p>
                        </div>
                        <div class="right">
                            <p class="time">{{item.releasetime.split(' ')[0]}}</p>
                            <!-- <p class="more">点击查看</p> -->
                        </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import ResourcesTop from '../../components/resources/ResourcesTop';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

const listEffect = (route) => {
    //列表
    const list = ref()

    //页数
    const page = ref(1)

    //总页数
    const total_page = ref()

    //获取详情
    const getList = async (id, page_id) => {
        let postData = {
            page_id: '/' + page_id,
            second_navigation_id: id,
            page: page.value
        }
        await post('/resource/get_resource', postData).then((res) => {
            // console.log('教育基地列表', res)
            list.value = res.data.data
            total_page.value = res.data.last_page
        })
    }

    //更换页数
    const handleChangePage = (new_page, pageSize) =>{
        page.value = new_page
    }

    watchEffect(() =>{
        getList(route.params.id, route.params.page_id)
    })

    return { list, page, total_page, getList, handleChangePage }
}

export default {
    name: 'ResourcesEducation',
    components: { Header, Footer, FloatingFrame, ResourcesTop },
    setup() {

        const  route = useRoute()

        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        const { list, page, total_page, getList, handleChangePage } = listEffect(route)

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        const { imgUrl }  = api

        return { imgUrl, isShow, header, list, page, total_page, handleChangePage }
    }
}
</script>

<style lang="scss" scoped>
.content_box{
    margin-top: 50px;
}
.content{
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
    .list{
        display: flex;
        padding: 30px 0;
        border-bottom: 1px solid #bbb;
        margin-bottom: 30px;
        cursor: pointer;
        &:last-child{
            border-bottom: none;
        }
        .left{
            width: 50%;
            @media screen and (max-width: 900px){
                width: 100%;
            }
            .title{
                color: rgba(47, 80, 158, 100);
                font-size: 20px;
                font-weight: 600;
            }
            .desc{
                color: rgba(16, 16, 16, 100);
                font-size: 16px;
                margin-top: 30px;
                line-height: 24px;
            }
        }
        .right{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media screen and (max-width: 900px){
                display: none;
            }
            .time{
                color: rgba(16, 16, 16, 100);
                font-size: 20px;
                font-weight: 600;
            }
            .more{
                color: #101010;
                font-size: 14px;
                padding: 10px 25px;
                border: 1px solid #BBBBBB;
                margin-left: 30px;
            }
        }
    }
}
</style>